const projectId = 'careerscope-vri';

export const environment = {
  dev: false,
  production: true,
  useEmulators: false,

  firebase: {
    apiKey: 'AIzaSyC9PQ5yqBQzQ3B-ZnTx5sNVglwP9Sxop9g',
    authDomain: 'careerscope-vri.firebaseapp.com',
    databaseURL: 'https://careerscope-vri.firebaseio.com',
    projectId,
    storageBucket: 'careerscope-vri.appspot.com',
    messagingSenderId: '748287607122',
    appId: '1:748287607122:web:bbc2655959d73e157a3a8b',
    measurementId: 'G-WCH5EK1BLV'
  },
  functionsURL: `https://app.careerscope.com`
};
