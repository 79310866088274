import { Component, OnDestroy, LOCALE_ID, Inject, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';
import { AptitudeService } from '../../_services/aptitude.service';
import { AuthService } from '../../_services/auth.service';
import { EvalueeService } from '../../_services/evaluee.service';
import { environment } from '../../../environments/environment';
import { AudioService } from '../../_services/audio.service';
import { FeaturesSecurityService } from '../../_services/features-security.service';
import { AssessmentService } from '../../_services/assessment.service';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { MatNavList, MatListItem } from '@angular/material/list';
import { MatIconButton, MatAnchor, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    animations: [
        trigger('fold', [
            transition(':leave', [style({ height: '*', overflow: 'hidden' }), animate('.3s ease', style({ height: 0 }))])
        ]),
        trigger('bar-fold', [
            transition(':enter', [style({ height: 0, overflow: 'hidden' }), animate('.3s ease', style({ height: '*' }))]),
            transition(':leave', [style({ height: '*', overflow: 'hidden' }), animate('.3s ease', style({ height: 0 }))])
        ]),
    ],
    standalone: true,
    imports: [NgIf, MatSidenavContainer, MatSidenav, MatToolbar, MatNavList, MatListItem, RouterLink, MatSidenavContent, MatToolbarRow, MatIconButton, MatIcon, NgTemplateOutlet, MatAnchor, MatButton, MatMenuTrigger, MatMenu, MatMenuItem, MatTooltip, AsyncPipe]
})
export class ShellComponent implements OnDestroy {
  private breakpointObserver = inject(BreakpointObserver);

  authSubscription: Subscription;
  currentYear = new Date().getFullYear();
  environment = environment

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );



  constructor(
    public as: AptitudeService,
    private auth: AuthService,
    public es: EvalueeService,
    public fss: FeaturesSecurityService,
    private router: Router,
    public dialog: MatDialog,
    public audio: AudioService,
    private assessmentService: AssessmentService,
    @Inject(LOCALE_ID) private locale: string
  ) { 
    this.authSubscription = this.auth.user$.subscribe(res => {
      if (!res) {
        this.dialog.closeAll();
        //this.router.navigateByUrl('/login');
      }
    })
  }

  signOut() {
    this.auth.signOut();
  }

  resetAnswers() {
    this.assessmentService.resetAnswersHQ();
  }

  completeExercises() {
    this.assessmentService.completeExercisesHQ();
  }

  completeInterests() {
    this.assessmentService.completeInterestsHQ();
  }

  changeLanguage(language: 'en' | 'es-US') {
    window.location.href = environment.production ? `https://app.careerscope.com/${language}/${this.router.url}` : `https://app-staging.careerscope.com/${language}/${this.router.url}`
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }
}
